import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import { StrongGold } from "../../../components/Textstyles/Styles";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 67",
  week: "Semana 10",
  day: "19",
  month: "May",
  monthNumber: "05",
  date: "2020-05-19",
  path: "/cronologia/semana-10#dia-19-may/",
};

const Day67 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} disclaimer={true}/>
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 431 nuevos positivos y 69 personas fallecidas. Permanecen
          hospitalizadas 124.789 personas, -444 menos que el día anterior, y 0
          reciben el alta. El número de casos ingresados en la UCI asciende a
          11.414, lo que supone una disminución de 23 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,0 % y la tasa de recuperados del 54,0 %.
        </ModText>

        <ModDailyHighlight>
          El gobierno negocia la <StrongGold>quinta prórroga</StrongGold> del
          estado de alarma
        </ModDailyHighlight>
        <ModText>
          El nuevo sistema de vigilancia de casos de coronavirus establecido por
          el Ministerio de Sanidad ha reducido el tramo, entre la fecha de
          inicio de síntomas y la de diagnóstico, de 7-14 días a tan sólo 4
          días.
        </ModText>
        <ModImage src="/images/svg/07_abr_test_rapidos.svg" alt="AtributoAlt" />

        <ModText>
          Por otra parte, el Ministerio de Sanidad también ha hecho público un
          documento técnico de recomendaciones para la programación de cirugías,
          durante la transición de la pandemia, con el objetivo de velar por la
          seguridad del paciente y del personal sanitario.
        </ModText>
        <ModText>
          Siguiendo las recomendaciones de los expertos y científicos, el
          Gobierno solicitará una nueva prórroga del estado de alarma hata el
          próximo 7 de junio. Asimismo, hoy ha comenzado a distribuir 9,6
          millones de mascarillas y 5 millones de guantes entre las distintas
          comunidades autónomas con la finalidad de frenar la transmisión del
          virus.
        </ModText>
        <ModText>En materia económica, ha puesto marcha dos medidas:</ModText>
        <ModDotList>
          El cuarto tramo de la Línea de Avales, con 20.000 millones de euros,
          para dar liquidez a pequeñas y medianas empresas y autónomos/as.
        </ModDotList>
        <ModDotList>
          Un{" "}
          <InlineLink link="https://www.mscbs.gob.es/ssi/covid19/trabajadores/home.htm">
            subsidio extraordinario
          </InlineLink>{" "}
          para las personas trabajadoras del hogar.
        </ModDotList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day67;
